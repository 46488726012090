<template>
  <b-card
    v-if="layoutEnabled || $route.name === 'societies-details'"
    :key="widgetKey"
    class="widget"
    style="overflow: auto"
    :class="{ stretch, 'disable-card': !enableCard, [`disable-card enable-card-${enableCardSize}`]: enableCardSize }"
    :body-class="[{ 'disable-card__card-body': !enableCard || enableCardSize }, bodyClass]"
    v-bind="$attrs"
  >
    <slot
      name="header"
      v-bind="{
        computedTitle,
        isEditable,
        isEditingName,
        setIsEditingName: (value) => isEditingName = value,
      }"
    >
      <span class="d-flex justify-content-between">
        <b-card-title v-if="title || appId" :class="titleClass">
          <div>
            <slot name="title" :title="computedTitle">
              {{ computedTitle }}
            </slot>
          </div>
        </b-card-title>
        
        <!-- Actions -->
        <div class="d-flex">
          <div v-if="appId && isEditable" class="p-50 zindex-4 settings mb-2 mt-n25 mr-1">
            <feather-icon
              icon="Trash2Icon"
              size="20"
              role="button"
              class="text-primary pointer"
              @click.stop.prevent="isUnshareModalVisible = true"
            />
            <feather-icon
              icon="Edit2Icon"
              size="20"
              class="text-primary ml-1 pointer"
              role="button"
              @click="isEditingName=true"
            />
            <!-- Unshare modal -->
            <unshare-modal
              v-model="isUnshareModalVisible"
              model-type="addons"
              @remove-item="handleRemoveItem"
            />
          </div>
          <slot name="header-actions" />
          <slot name="header-newactions" />
        </div>
      </span>
    </slot>
    <slot />

    <!-- Edit App info modal -->
    <b-modal
      v-if="appId"
      v-model="isEditingName"
      :title="$t('layouts.edit-widget')"
      :ok-title="$t('form.actions.save')"
      @ok="handleUpdatedTagline"
    >
      <!-- Name -->
      {{ $t('layouts.widget-name') }}
      <form-translation-table
        v-model="computedAppNames"
        class="mt-50 mb-3"
        :value-placeholder="$t('layouts.widget-name')"
        label-attribute="name"
        value-attribute="text"
        @input="handleTitleChange"
      />
      <!-- Tagline -->
      {{ $t('layouts.widget-tagline') }}
      <form-translation-table
        id="app-description"
        v-model="appDescription"
        class="mt-50"
        label-attribute="description"
        value-attribute="text"
        :value-placeholder="$t('layouts.widget-tagline')"
        :name="$t('organizations.edit.description.name')"
        :placeholder="$t('organizations.edit.description.placeholder')"
      />
    </b-modal>
  </b-card>
</template>

<script>
import { AppIDLUT } from '@copernicsw/community-common';
import { ENABLED_APPS_GETTERS, ENABLED_APPS_MUTATIONS, ENABLED_APPS_ACTIONS } from '@/store/enabled-apps/enabled-apps-store-constants';
import FormTranslationTable from '@core/components/form-translation-table/FormTranslationTable.vue';
import { translateTranslationTable } from '@/@core/libs/i18n/utils';
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';
import UnshareModal from '@/@core/components/modal/UnshareModal.vue';
import CardWidgetMixin from '../mixins/widgets/CardWidgetMixin';
import { randomString } from '../utils/utils';

/**
 * Basic Widget wrapper class for all widgets.
 */
export default {
  name: 'Widget',
  components: { FormTranslationTable, UnshareModal },
  mixins: [CardWidgetMixin, ToastNotificationsMixin],
  props: {
    // we use app-id instead of the app itself because we can't use the proper :layout.sync with the store.
    // so we lose reactivity.
    appId: {
      type: Number,
      default: null,
    },
    id: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: '',
    },
    bodyClass: {
      type: [String, Array, Object],
      default: '',
    },
    titleClass: {
      type: [String, Array, Object],
      default: '',
    },
    /**
     * If true, then the widget will streach to 100% minus the content padding.
     */
    stretch: Boolean,
  },
  data() {
    return {
      isEditingName: false,
      nameTranslationTable: null,
      appDescription: {},
      widgetKey: randomString(10),
      timer: false,
      widget: {},
      customName: {},
      isUnshareModalVisible: false,
    };
  },
  computed: {
    currentLocale() {
      return this.$store.state.locale.currentLocale;
    },
    isEditable() {
      return this.$store.getters[ENABLED_APPS_GETTERS.isEditingLayout];
    },
    storedApp() {
      const appNameKey = this.appId === 120 ? 'contacts'  : this.appId === 123 ? 'societies' : AppIDLUT[this.appId];
      return this.$store.getters[ENABLED_APPS_GETTERS.enabledApps][appNameKey];
    },
    appNameKey() {
      return this.appId === 120 ? 'contacts'  : this.appId === 123 ? 'societies' : AppIDLUT[this.appId];
    },
    layout() {
      const appNameKey = this.appId === 120 ? 'contacts'  : this.appId === 123 ? 'societies' : AppIDLUT[this.appId];
      if (this.appId === 57 || this.appId === 35) {
        return this.$store.getters[ENABLED_APPS_GETTERS.layout].find(({ appKey }) => appKey === this.id);
      }
      if (this.appId === 32) {
        return this.$store.getters[ENABLED_APPS_GETTERS.layout].find(({ appKey }) => appKey === 'organization-null');
      }
      return this.$store.getters[ENABLED_APPS_GETTERS.layout].find(({ appKey }) => appKey === appNameKey);
    },
    layoutEnabled() {
      const appNameKey = this.appId === 120 ? 'contacts'  : this.appId === 123 ? 'societies' : AppIDLUT[this.appId];
      return this.$store.getters[ENABLED_APPS_GETTERS.enabledApps][appNameKey] != null ? this.$store.getters[ENABLED_APPS_GETTERS.enabledApps][appNameKey].inLayout : true;
    },
    computedAppNames() {
      if (this.type !== null) {
        return this.storedApp?.customization?.[this.type];
      }
      return this.storedApp?.customization?.customizationName || this.storedApp?.name;
    },
    computedTitle() {
      if (this.type !== null) {
        if (this.storedApp?.customization?.[this.type] != null && this.storedApp?.customization?.[this.type] !== '') {
          return translateTranslationTable(this.currentLocale, this.storedApp?.customization?.[this.type]);
        }
      }

      if ((!this.type || this.type == null) && this.storedApp?.customizationName != null && this.storedApp?.customizationName !== '') {
        return translateTranslationTable(this.currentLocale, this.computedAppNames);
      }

      if (this.title) {
        return this.title;
      }

      return translateTranslationTable(this.currentLocale, this.storedApp?.name);
    },
  },
  created() {
    this.getWidgetDescription();
  },
  methods: {
    handleTitleChange(customNameTable) {
      this.customName = customNameTable;
      this.$store.commit(ENABLED_APPS_MUTATIONS.updateAppCustomName, {
        appId: this.appId,
        customAppId: this.id,
        customNameTable,
      });
    },
    getEnabledApp(appNameKey) {
      return this.$store.getters[ENABLED_APPS_GETTERS.enabledApps][appNameKey];
    },
    async handleUpdatedTagline() {
      const ob = {};
      if (this.type !== null) {
        ob[this.type] = this.customName;
      } else {
        ob.customizationName = this.customName;
      }
      const response = await this.$store.dispatch('changeTaglineApp', {
        appID: this.appId,
        tagline: this.appDescription,
        customization: { ...this.$store.getters.apps.apps[this.appId].customization, ...ob },
      });
      this.widgetKey = randomString(10);
      await this.$store.dispatch(ENABLED_APPS_ACTIONS.fetchEnabledApps);
      return response;
    },
    async handleRemoveItem(executeDelete) {
      if (executeDelete === true) {
        const enabledApp = this.getEnabledApp(this.appNameKey);
        try {
          await this.$store.dispatch('editItem', {
            item: {
              itemType: 'publishAddon',
              customName: 'addons',
              requestConfig: {
                addonKey: enabledApp.key ?? this.appNameKey,
              },
            },
          });

          this.$store.commit('SET_ITEM_LITERAL', {
            itemType: 'addon',
            data: {
              paginated: {},
              unpaginated: [...(this.$store.getters.addon?.unpaginated || []), { addonKey: this.appNameKey }],
            },
          });
          await this.fetchData();
          this.notifySuccess(this.$t('layouts.message.saved-success'));
        } catch {
          this.notifyError(this.$t('error-message.general-error'));
        }
      }
    },
    async fetchData() {
      await this.$store.dispatch('getItems', {
        itemType: 'addons',
        page: 1,
        forceAPICall: true,
        requestConfig: {
          count: 100,
          orderByDate: -1,
          type: 'addons',
        },
      });
    },

    getWidgetDescription() {
      const app = Object.values(
        this.$store.getters[ENABLED_APPS_GETTERS.enabledApps],
      ).filter((item) => {
        if (item.id === this.appId) {
          return item.name;
        }
      });
      if (app[0]?.tagline) {
        this.appDescription = app[0].tagline;
      }
      this.widget = app[0];
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-include";

.widget {
  margin-bottom: 0;
}

.stretch {
  height: auto;
  &::v-deep {
    .card-body {
      display: flex;
      flex-direction: column;
    }
  }
}
.settings{
  background-color:lighten($primary, 50%);
  border-radius: 12px;
  width: 68px;
  height: 35.5px
}

.disable-card {
  border-width: 0;
  border-bottom: 1px solid $border-color;
  border-radius: 0;
  padding-bottom: 1rem;
  &__card-body {
    padding: 0;
  }
}
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    .enable-card-#{$breakpoint} {
      border-width: $card-border-width;
      border-radius: 1.2rem;
      padding-bottom: 0;
      .disable-card__card-body {
        padding: $card-spacer-y $card-spacer-x;
      }
    }
  }
}
</style>
